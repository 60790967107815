/* Common CSS */
body {
  font-size: 100%;
  margin: 0 auto;
}
h1 {
  font-family: "Viga", sans-serif;
  font-weight: normal;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-weight: bold !important;
  font-size: 1.55em !important;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 1.25em;
}

main {
  margin: 5em auto;
  width: 85%;
}
img {
  width: 100%;
}
.center {
  text-align: center;
}

/* Navbar CSS */
.navbar-brand img {
  width: 100px;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar .nav-link {
  text-transform: uppercase;
  padding: 1.5rem !important;
  font-size: 1em;
  text-align: center;
  color: #000099;
  font-weight: bold;
  text-decoration: none;
}
.navbar .nav-link:focus,
.navbar .nav-link:hover {
  color: #00ccff !important;
  border-bottom: 3px solid #000099;
  transition: 0.6s;
}

.navbar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #bdf6ff, #12d2ff, #126dff, #6037f4) 1;
}
.navbar-bottom {
  margin-bottom: 5.7em;
}

/* Home page styling: CSS */
/* Banner CSS */
.banner {
  background-image: linear-gradient(to right, #12d2ff, #126dff);
  height: auto;
  padding-bottom: 5em;
}
.banner .row {
  padding-top: 10%;
}
.banner-btn {
  padding-left: 5em !important;
}
/* .banner img {
  width: 100%;
} */
.banner h1 {
  text-transform: uppercase;
  color: white;
}
.banner .btn {
  background-color: white;
  padding: 10px;
  width: 9em;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
}
.banner .btn a {
  text-decoration: none;
  color: blue;
  padding: 10px;
  width: 9em;
}
.banner .btn:hover {
  transition: 0.6s;
}
.banner .btn a:hover {
  color: white;
}
.banner .col {
  padding: 10px;
}

/* ILA MS Solution section css */
.ilams-solutions .row {
  background-image: linear-gradient(to right, #12d2ff, #126dff);
  color: white;
  border-radius: 12px;
  margin: 5% auto;
  padding: 2%;
}
.ilams-solutions .solution-items {
  padding: 10% 0;
}
.solution-items {
  padding: 5%;
}

.interested {
  background-color: #12d2ff;
  color: #000099;
  border-radius: 12px;
  padding: 5%;
}
.interested h1 {
  padding-bottom: 1em;
}
.interested .btn {
  width: 40%;
  padding: 15px;
  background-color: #000099;
  font-weight: bold;
  font-family: Roboto;
  border-radius: 10px;
}
.interested .btn a {
  color: #ffffff;
  text-decoration: none;
}
.interested .btn svg {
  color: #12d2ff;
  margin-left: 8px;
}
.business {
  background-color: #12d2ff;
  margin: 5% auto;
  padding: 30px;
  border-radius: 12px;
}
.business .center {
  color: white;
}

/* Footer CSS */
.footer {
  background-color: #000099;
  color: white;
}
.footer a {
  color: white;
  text-decoration: none;
  display: flex;
}
.footer a:hover {
  color: #00ccff;
  transition: 0.6s;
}
.footer .row {
  padding: 5%;
}
.footer-left img {
  padding: 10%;
}

.footer-middle {
  border-bottom: 4px solid #12d2ff;
  width: 85%;
  margin-left: 5%;
}
.footer-right {
  border-left: 4px solid #12d2ff;
}
.footer-right .icons a:hover {
  transition: 0.6s;
  color: #12d2ff;
}
.footer-container footer {
  margin-left: 15%;
}
/* About page styling: CSS */
.about-text {
  padding: 5em;
  background-image: linear-gradient(to top, white, #12d2ff);
  color: #000099;
}
.section-journey {
  color: #000099;
  width: 100%;
  height: 100%;
}
.nutshell-point {
  align-items: center;
  padding: 1.7em;
  border-radius: 1em;
  box-shadow: 0px 0px 8px #ffffff;
}
.nutshell-point img {
  border-radius: 0.5em;
  height: 100%;
  width: 100%;
}

.about-nutshell {
  background-color: #000099;
  color: white;
  padding: 4em;
  margin: 1em 0;
  border-radius: 0.5em;
  text-align: left;
}
.about-nutshell p {
  text-align: justify;
}
.team-section {
  color: #000099;
}
.team-pera {
  color: white;
  background-color: #000099;
  padding: 1.8em;
  border-radius: 0.5em;
  font-weight: bold;
  margin: 2em 0;
}
.team-card {
  color: #000099;
  font-weight: bold;
  text-align: start;
  box-shadow: 10px 10px 9px #dae4fc;
  border-radius: 2em;
  width: 100%;
  height: 100% !important;
}
.team-img {
  height: 100%;
  width: 100%;
}
.team-text {
  margin-bottom: 4em;
}
.team-link {
  color: white;
  background-color: #000099;
  padding: 0.5em;
  border-radius: 0.4em;
  text-decoration: none;
}
.team-hr {
  margin: 6em 0;
  width: 100%;
  padding: 0.1em;
  opacity: 10%;
}
.join-team {
  background-color: #00ccff;
  padding: 3em;
  margin: 3em 0;
  border-radius: 0.5em;
}
.join-team p {
  color: white;
  font-weight: bold;
}
.contact-btn {
  background-color: #000099;
  color: white;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  font-weight: bold;
}

/* Contact page styling: CSS */
.contact-text h1 {
  color: #000099;
}
.contact-text h3 {
  color: #000099;
  padding-bottom: 2em;
}
.contact-page {
  background-image: linear-gradient(
    135deg,
    #ffffff,
    #bdf6ff,
    #12d2ff,
    #126dff,
    #000099
  );
  padding: 1em;
}
.contact-page .row a {
  text-decoration: none;
}
.contact-page .row span {
  font-size: 1.25em;
  color: #000099;
}
.send-button {
  float: right;
}
.info-number {
  background-color: #00ccff;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}
.boxOne {
  background-color: #00ccff;
  color: #000099;
  margin: 0 1em 1em 0;
  border-radius: 0.3em;
  align-items: center;
}
.boxTwo {
  background-color: #00ccff;
  color: #000099;
  margin: 0 1em 1em 0;
  border-radius: 0.3em;
  align-items: center;
}
.boxThree {
  background-color: #00ccff;
  color: #000099;
  margin: 0 1em 0 0;
  border-radius: 0.3em 0.3em 0 0;
  align-items: center;
}
.map {
  margin: 1em -0.75em -0.5em -0.75em;
}

.contact-input {
  background-color: #00ccff;
  border-radius: 5px;
}
.contact-form {
  padding: 0 2em 2em 2em;
}

/* ContactForm component section css */
.contactForm input,
.contactForm select,
.contactForm textarea {
  background-color: #bdf6ff;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-weight: bold;
  color: #12d2ff;
}
.contactForm .btn {
  color: #12d2ff;
  font-weight: bold;
  padding: 12px 20px;
  background-color: #000099;
  border-radius: 10px;
}
.contactForm svg {
  color: #000099;
}

.icon-img {
  width: 5em;
}
.iconImg {
  width: 5em;
}
.question {
  background-color: #00ccff;
  padding: 3em;
  border-radius: 0.5em;
  color: #000099;
}
.question h3 {
  margin-bottom: 1em;
}
.answer {
  background-color: #000099;
  color: white;
  padding: 4em;
  border-radius: 0.5em;
}
.faq-text {
  align-items: center;
  color: #000099;
}
.faq-question {
  margin: 3em;
}
.answer hr {
  width: 70%;
  padding: 0.1em;
}

/* others */

/* .section-journey h1 {
  margin: auto;
  padding: 0.4em  0.3em;
  background-color: #00ccff;
  width: 6em;
  border-radius: 0.2em;
  align-items: center;
  position: relative;
  top: 0.7em;
} */
/* .section-journey p{
  background-color: #000099;
  color: white;
  padding: 3em;
  border-radius: 0.5em;
  font-weight: bold;
} */
/* .today-section{
  background-color: #000099;
  color: white;
  border-radius:0.5em ;
  margin-top: 10em;
  width: 100%;
  height: 100%;

}
.today-section p {
  font-weight: bold;
}
.today-section h1{
  margin: auto;
  padding: 0.4em  0.3em;
  background-color: #00ccff;
  width: 4em;
  border-radius: 0.2em;
  align-items: center;
  position: relative;
  color: #000099;
  bottom: 1.3em;
}
.paraOne{
  padding: 6em 3em 4em 3em;
}
.today-section img {
  width: 10em;
}
.pad{
  padding-bottom: 5em;
}
.future-section h1{
  margin: auto;
  padding: 0.4em  0.3em;
  background-color: #00ccff;
  width: 5em;
  border-radius: 0.2em;
  align-items: center;
  position: relative;
  color: #000099;
  bottom: -0.7em;
}
.future-section{
  margin: 5em 0;

}
.future-accordion{
  align-items: center;
  background-color: #00ccff;
}
.accordion-header{
  align-items: center;
}
.accordion-body{
  margin: 3em;
}
.download-btn{
  background-color:#00ccff;
  color: white;
  font-weight: bold;
  padding: 1em;
  border-radius: 0.7em;
  align-items: center;
  margin-bottom: 2em;
}
.download-content{
  background-color: #000099;
  color: white;
  font-weight: bold;
  border-radius: 0.5em;
}
.download-content p{
  padding: 3em 0;
  font-weight: bold;
}
.btnIcon{
margin: 1 0.4em;
} */
