
/* Responsive CSS */
@media only screen and (max-width: 990px) {
    .navbar-bottom {
      margin-bottom: 3.7em;
    }
    .banner-btn {
      padding-top: 2em;
    }
    #solutions h1 {
      margin-bottom: 2em;
    }
    .interested .btn {
      width: 55%;
    }
    .contact-input {
      margin-top: 2.5em;
    }
    .footer .footer-right {
      border: none;
    }
    .footer-left img {
      padding: 0;
    }
    .footer-container footer {
      margin-left: auto;
      text-align: center;
    }
  }
  @media only screen and (max-width: 775px) {
    .navbar-bottom {
      margin-bottom: 3.7em;
    }
    .interested .btn {
      width: 70%;
    }
    .footer .footer-right {
      border: none;
    }
    .footer-container footer {
      margin-left: auto;
      text-align: center;
    }
  }